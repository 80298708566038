import { parse, stringify } from 'yaml'
import uuid from '@aeppic/shared/uuid'

export interface TranslatorOptions {
  allowEmptyStrings?: Boolean
}

const INVERSE_CACHE_KEY = new Map<string,string>()
const TRANSLATION_CACHE = new Map<string,any>()

export default class Translator {

  private _translationTables = {}
  private _missingTranslations = {}

  private _devMode = false

  private _currentLanguage = 'en-US'

  private _fallbackLanguage = 'en-US'

  private _options

  constructor(options?: TranslatorOptions) {
    if (typeof navigator !== 'undefined') {
      this.currentLanguage = navigator.language
    }

    this._options = options || { 
      allowEmptyStrings: false 
    }
  }

  set options(_options: TranslatorOptions) {
    this._options = _options
  }

  get options(): TranslatorOptions {
    return this._options
  }

  set currentLanguage(languageCode: string) {
    this._currentLanguage = languageCode
  }

  get currentLanguage(): string {
    return this._currentLanguage
  }

  set fallbackLanguage(languageCode: string) {
    this._fallbackLanguage = languageCode
  }

  get fallbackLanguage(): string {
    return this._fallbackLanguage
  }

  set devMode(devMode: boolean) {
    this._devMode = devMode
  }

  get devMode(): boolean {
    return this._devMode
  }

  get missingTranslations() {
    return this._missingTranslations
  }

  public getMissingTranslationsAsYaml(): string {
    let result = stringify(this._missingTranslations)

    // empty yaml is '{}' should appear as empty text
    const EMPTY_YAML_REGEXP = /^\s*\{\}\s*$/

    if (!EMPTY_YAML_REGEXP.test(result)) {
      return result
    } else {
      return ''
    }
  }

  /**
   *
   * @param translationsYaml Example:
   *   en-US:
   *    greeting: hello world
   *   de-DE:
   *    greeting: hallo welt
   *   fr-FR:
   *    greeting: bonjour monde
   */
  public addTranslations(translationsYaml: string, { cacheKey = null }: { cacheKey?: string } = {}) {
    let cacheEntry = cacheKey ? TRANSLATION_CACHE.get(cacheKey) : null
    let fallbackCacheKey = null

    if (!cacheKey) {
        fallbackCacheKey = INVERSE_CACHE_KEY.get(translationsYaml) 

        if (!fallbackCacheKey) {
            fallbackCacheKey = uuid()
            INVERSE_CACHE_KEY.set(translationsYaml, fallbackCacheKey)
            cacheKey = fallbackCacheKey
        }

        cacheEntry = TRANSLATION_CACHE.get(fallbackCacheKey)
    }

    if (cacheEntry) {
        this._translationTables = cacheEntry
    } else {
        let translations = {}

        try {
            translations = parse(translationsYaml)
        } catch (e) {
            // tslint:disable-next-line
            console.error('Error loading translation YAML', e)
        }
        
        for (const key in translations) {
            this._translationTables[key] = { ...this._translationTables[key], ...translations[key] }
        }

        // Cache it and mark the string
        if (cacheKey) {
            TRANSLATION_CACHE.set(cacheKey, this._translationTables)
        }
    }
  }

  public clearTranslations() {
    this._translationTables = {}
    this._missingTranslations = {}
  }

  public getAllKnownTranslationEntries(defaultLanguage = this._currentLanguage) {
    const entries = {}

    for (const [language, translationTable] of Object.entries(this._translationTables)) {
      for (const key of Object.keys(translationTable)) {
        const alreadyKnown = entries[key]

        if (language === defaultLanguage) {
          entries[key] = translationTable[key]
        } else if (!alreadyKnown) {
          entries[key] = translationTable[key]
        }
      }
    }

    return entries
  }

  public canTranslate(...keys: string[]): boolean {
    let result = this._translateInternal(this._currentLanguage, ...keys)
    return !result.missing
  }

  public translate(...params: string[]): string {
    let keys

    if (params.length === 1 && typeof params[0] === 'string' && params[0].startsWith('translate:')) {
      keys = params[0].substring(10).split('|').map(s => s.trim())
    } else {
      keys = params
    }

    return this.translateToLanguage(this._currentLanguage, ...keys)
  }

  public translateToLanguage(languageCode, ...keys: string[]): string {
    const result  = this._translateInternal(languageCode, ...keys)

    if (result.missing) {
      const fallbackLanguage = this._getBestFallbackLanguage(languageCode)
      const fallbackResult = this._translateInternal(fallbackLanguage, ...keys)
      return fallbackResult.translation
    }
    else {
      return result.translation
    }
  }

  private _translateInternal(languageCode, ...keys: string[]) {
    const result = {
      translation: '',
      missing: true
    }

    if (!languageCode || !keys.length) {
      return result
    }

    const translationTable = this._translationTables[languageCode]
    const translation = this._getTranslationFromTable(translationTable, keys)

    if (translation || ((translation === '') && this.options.allowEmptyStrings)) {
      result.translation = translation
      result.missing = false

      return result
    }

    const mostSpecificKey = keys[0]
    const fallbackText = keys[keys.length - 1]
    this._recordMissingTranslation(languageCode, mostSpecificKey, fallbackText)

    result.missing = true
    result.translation = fallbackText
    return result
  }

  private _getBestFallbackLanguage(languageCode) {
    const lang = languageCode.length === 2 ? languageCode : languageCode.substring(0, 2)

    for (let lc in this._translationTables) {
      if ((lc !== languageCode) && lc.startsWith(lang)) {
        return lc
      }
    }

    return this._fallbackLanguage
  }

  private _getLanguageCodeParts(languageCode: string) {
    const splitParts = languageCode.split('-')

    return splitParts.reduce((prev, cur, i) => {
      if (prev.length === 0) {
        prev.push(cur)
        return prev
      }

      const previousValue = prev[i - 1]

      prev.push(`${previousValue}-${cur}`)
      return prev
    }, [])
  }

  private _getTranslationFromTable(translationTable, keys) {
    if (!translationTable) {
      return null
    }

    for (const key of keys) {
      const translation = translationTable[key]

      if (translation) {
        return translation
      }
      else if (translationTable.hasOwnProperty(key)) {
        // TODO: stringify falsey values
        return ''
      }
    }

    return null
  }

  private _recordMissingTranslation(languageCode, key, fallbackText) {
    if (!this._missingTranslations[languageCode]) {
      this._missingTranslations[languageCode] = {}
    }

    this._missingTranslations[languageCode][key] = fallbackText
  }
}
